import React, { useContext } from 'react'
import './ExistingData.scss'
import '../Header/Header.scss'
import { Col, Row, Layout } from 'antd'
import { images } from '../../config/images'
import DataTable from '../DataTable/DataTable'
import { Header } from 'antd/lib/layout/layout'
import { Dropdown, Menu, Space, Avatar } from 'antd'
import PageHeading from '../PageHeading/PageHeading'
import queryString from 'query-string'
import { ReactComponent as Logo } from '../../assets/icons/page-logo.svg'
import { ReactComponent as Dropicon } from '../../assets/icons/drop-arrow.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/log-out.svg'
import { getItemFromLocalStorage } from '../../utils/helperFunctions/localStorageHelperFunctions'
import { AuthContext } from '../../store/auth'
import {
  filteredOptions,
  getColumnSearchProps,
} from '../../utils/helperFunctions/helperFunctions'
import { useSelector } from 'react-redux'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import { useLocation } from 'react-router'
import { DATE_FORMATS } from '../../utils/constants/constant'

const ExistingData = ({ heading, label }) => {
  const projectData = JSON.parse(localStorage.getItem('projectData'))
  const PredictionData = getItemFromLocalStorage('existingPredictionsData')
  const { search } = useLocation()
  const { title } = queryString.parse(search)
  const { accuracyOptions, invoiceNumberOptions } = useSelector(
    staticOptionsSelector
  )
  const { logout } = useContext(AuthContext)

  const invoicesForInvoiceDateData = getItemFromLocalStorage(
    'existingInvoicesForInvoiceDateData'
  )

  const menu = (
    <Menu
      items={[
        {
          label: (
            <div onClick={logout} className="logout-menu-item">
              <span className="dropdown-icons">
                <LogoutIcon />
              </span>
              Log Out
            </div>
          ),
          key: '2',
        },
      ]}
    />
  )
  const predictionColumns = [
    {
      title: 'Accuracy',
      dataIndex: 'accuracy',
      filters: filteredOptions('text', accuracyOptions, '-'),
      onFilter: (value, record) => record.accuracy.indexOf(value) === 0,
      key: 0,
    },
    {
      title: 'Revenue Amount',
      dataIndex: 'predictedRevenueAmount',
      key: 1,
      render: (record) => `$${record}`,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      ...getColumnSearchProps('startDate', DATE_FORMATS.DD_MMM_YYYY),
      key: 2,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      ...getColumnSearchProps('endDate', DATE_FORMATS.DD_MMM_YYYY),
      key: 3,
    },
    {
      title: 'Received Month',
      dataIndex: 'receivedMonth',
      ...getColumnSearchProps('receivedMonth', DATE_FORMATS.MMM_YYYY, 'month'),
      key: 4,
    },
  ]
  const invoiceColumns = [
    {
      title: 'Accuracy',
      dataIndex: 'accuracy',
      filters: filteredOptions('text', accuracyOptions, '-'),
      onFilter: (value, record) => record.accuracy.indexOf(value) === 0,
      ellipsis: true,
      width: 120,
      render: (record) => record || <div className="text-center">-</div>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      ...getColumnSearchProps('startDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      width: 120,
      render: (record) => record || <div className="text-center">-</div>,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      ...getColumnSearchProps('endDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      width: 120,
      render: (record) => record || <div className="text-center">-</div>,
    },
    {
      title: 'Received Month',
      dataIndex: 'receivedMonth',
      ...getColumnSearchProps('receivedMonth', DATE_FORMATS.MMM_YYYY, 'month'),
      ellipsis: true,
      width: 180,
      render: (record) => record || <div className="text-center">-</div>,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      ellipsis: true,
      width: 150,
      render: (record) => `$${record}`,
    },
    {
      title: 'Received Amount',
      dataIndex: 'receivedAmount',
      ellipsis: true,
      width: 150,
      render: (record) => `$${record}`,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      ...getColumnSearchProps('invoiceDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Receive Date',
      dataIndex: 'receiveDate',
      ...getColumnSearchProps('receiveDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      width: 150,
      render: (record) => record || <div className="text-center">-</div>,
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      filters: filteredOptions('text', invoiceNumberOptions, '-'),
      onFilter: (value, record) => {
        return record.invoiceNumber.indexOf(value) === 0
      },
      ellipsis: true,
      width: 150,
    },
  ]
  return (
    <>
      <Layout className="header-wrap">
        <Header className="header">
          <div className="page-header">
            <div className="logo">
              <a href="/">
                <Logo />
              </a>
            </div>
            <div className="page-action">
              <ul className="list-items">
                <li>
                  <Dropdown
                    overlay={menu}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    trigger={['click']}
                    autoFocus={true}
                  >
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <Space>
                        <div className="profile-icon">
                          <Avatar src={images.profile} size={40} />
                        </div>
                        <div className="drop-icon">
                          <Dropicon />
                        </div>
                      </Space>
                    </a>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </Header>
      </Layout>
      <Layout>
        <div className="page-wrapper existing-data-wrap">
          <div className="content-wrap">
            <div className="project-overview ">
              <Row gutter={[24, 20]}>
                <Col md={24}>
                  <Row gutter={[24, 20]}>
                    <Col sm={24} md={10} lg={10} xl={5}>
                      <span className="title">Project Name</span>
                      <h3 className="name">{projectData?.projectName}</h3>
                    </Col>
                    <Col sm={24} md={8} lg={8} xl={5}>
                      <span className="title">Project Model</span>
                      <h3 className="name">{projectData?.projectModel}</h3>
                    </Col>
                    {projectData?.csm && (
                      <Col sm={24} md={6} lg={6} xl={5}>
                        <span className="title">CSM</span>
                        <h3 className="name">{projectData?.csm}</h3>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div className="prediction-details">
            {
              <>
                <div className="pageheader-wrap">
                  <PageHeading
                    label={
                      heading === title
                        ? title
                        : 'Invoice Details'
                          ? 'Existing Invoices with same invoice date'
                          : 'Existing Predictions between entered start date and end date'
                    }
                  />
                </div>
                <DataTable
                  tableLayout="fixed"
                  dataSource={
                    heading === 'Invoice Details'
                      ? invoicesForInvoiceDateData
                      : PredictionData
                  }
                  rowKey={(record) => record?.invoiceId || record?.predictionId}
                  pagination={false}
                  columns={
                    heading === 'Invoice Details'
                      ? invoiceColumns
                      : predictionColumns
                  }
                />
              </>
            }
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ExistingData
