import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import authReducer from '../reducer/authSlice'
import projectByIdReducer from '../reducer/getProjectsByIdSlice'
import projectIdReducer from '../reducer/projectIdSlice'
import predictionByIdReducer from '../reducer/getPredictionsByIdSlice'
import getOptionsReducer from '../reducer/getOptionsSlice'
import projectListReducer from '../reducer/projectListSlice'
import invoiceDetailsReducer from '../reducer/invoiceDetailsSlice'
import userActivitiesReducer from '../reducer/userActivitiesSlice'
import userListReducer from '../reducer/userListSlice'
import companyListReducer from '../reducer/companyListSlice'
import projectCompanyAndClientsReducer from '../reducer/projectCompanyAndClientsSlice'
import companyIdReducer from '../reducer/companyIdSlice'
import companyByIdReducer from '../reducer/getCompanybyIdSlice'
import rateCardDetailsReducer from '../reducer/rateCardDetailsSlice'
import techStackListReducer from '../reducer/techStacksSlice'
import todoListReducer from '../reducer/todoListSlice'
import leaveBalanceReducer from '../reducer/leaveBalanceSlice'
import jobSchedulerListReducer from '../reducer/jobSchedulerListSlice'
import generateInvoiceReducer from '../reducer/generateInvoiceSlice'
import timeSheetsDataReducer from '../reducer/timeSheetsDataSlice'
import projectNotesListReducer from '../reducer/projectNotesByIdSlice'
import salesPersonsListReducer from '../reducer/salesPersonsSlice'
import incentiveDetailsByIdReducer from '../reducer/getIncentiveDetailsByIdSlice'
import accountNameEntitiesReducer from '../reducer/accountNameEntitiesSlice'
import transactionsDetailsByIdReducer from '../reducer/getTransactionsByIdSlice'
import revenueListReducer from '../reducer/revenueListSlice'
import providerListReducer from '../reducer/providerListSlice'
import invoiceDashboardReducer from '../reducer/invoiceDashboardSlice'

const persistConfig = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({
  auth: authReducer,
  projectId: projectIdReducer,
  projectById: projectByIdReducer,
  predictionById: predictionByIdReducer,
  staticOptions: getOptionsReducer,
  projectList: projectListReducer,
  invoiceDetails: invoiceDetailsReducer,
  userActivities: userActivitiesReducer,
  userList: userListReducer,
  companyList: companyListReducer,
  projectCompanyAndClients: projectCompanyAndClientsReducer,
  companyId: companyIdReducer,
  companyById: companyByIdReducer,
  rateCardDetails: rateCardDetailsReducer,
  techStackList: techStackListReducer,
  todoList: todoListReducer,
  leaveBalanceList: leaveBalanceReducer,
  jobSchedulerList: jobSchedulerListReducer,
  timeSheetName: generateInvoiceReducer,
  invoiceTimesheetsData: timeSheetsDataReducer,
  projectNotesList: projectNotesListReducer,
  salesPersonsList: salesPersonsListReducer,
  incentiveDetailsById: incentiveDetailsByIdReducer,
  accountNameEntities: accountNameEntitiesReducer,
  transactionsDetailsById: transactionsDetailsByIdReducer,
  revenueList: revenueListReducer,
  providerList: providerListReducer,
  dashboardData: invoiceDashboardReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export const persistor = persistStore(store)
