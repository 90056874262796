import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, Row, Spin } from 'antd'
import dateFormat from 'dateformat'
import moment from 'moment'

import './AddMultiplePredictions.scss'
import {
  Button,
  CustomizeModal,
  DataTable,
  DateField,
  InputField,
  PopupConfirm,
  ProjectDetailField,
  SelectField,
  DividerLine,
} from '..'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { ReactComponent as AddPackage } from '../../assets/icons/plus.svg'
import {
  staticOptionsSelector,
} from '../../redux/reducer/getOptionsSlice'
import { predictionDetailsSelector } from '../../redux/reducer/getPredictionsByIdSlice'
import { projectDetailsSelector } from '../../redux/reducer/getProjectsByIdSlice'
import {
  addMultiplePredictions,
  getExistingPredictionForGivenDates,
  getLatestPredictionOfProject,
  getPredictionDetailsById,
} from '../../utils/api/predictionAndInvoiceServices'
import {
  dateVariables,
  DATE_FORMATS,
  invoiceCycles,
  NO,
  YES,
  TOAST,
  DATE_PICKER,
  STATUS,
} from '../../utils/constants/constant'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
  VALIDATION_MESSAGES,
} from '../../utils/constants/messages'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import {
  removeItemFromLocalStorage,
  setItemInLocalStorage,
} from '../../utils/helperFunctions/localStorageHelperFunctions'
import './AddInvoiceOrPredictionModule.scss'
import {
  receivedMonthRule,
  subMissionTypeRule,
} from '../../utils/constants/validationRules'
import { format } from 'date-fns'
import ProjectNamestagRender from './ProjectNamesTagRender'

const AddMultiplePredictions = ({
  onClose,
  existingPredictionsColumns,
  setIsPopupVisible,
  todoProjectId,
  fetchTodoList,
  isTMretainerPrepaidModel,
  currencySymbol,
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const { projectId } = useSelector((state) => state.projectId)
  const { predictionDetails } = useSelector(predictionDetailsSelector)
  const { projectDetails } = useSelector(projectDetailsSelector)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [amountValidation, setAmountValidation] = useState([])
  const [incorrectDateRangeValidation, setIncorrectDateRangeValidation] =
    useState([])
  const [startDateValidation, setStartDateValidation] = useState([])
  const [endDateValidation, setEndDateValidation] = useState([])
  const [existingPredictions, setExistingPredictions] = useState([])
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)
  const [isFormDataChanged, setIsFormDataChanged] = useState(false)
  const [lastPredictionData, setLastPredictionData] = useState()
  const [dayDifference, setDayDifference] = useState(0)
  const currentPageBaseUrl = window.location.origin
  const { accuracyOptions, projectNameOptions, submissionTypeOptions } =
    useSelector(staticOptionsSelector)
  const [isFetchingPredictionData, setIsFetchingPredictionData] =
    useState(false)

  const setPredictionsDate = (predictionData, currentField) => {
    const derivedStartDate =
      predictionData?.predictionDetails[0]?.endDate &&
      new Date(predictionData?.predictionDetails[0]?.endDate)
    if (derivedStartDate) {
      derivedStartDate.setDate(derivedStartDate.getDate() + 1)
    }

    derivedStartDate &&
      onDateChange(
        moment(derivedStartDate),
        null,
        ['', 'startDate'],
        currentField
      )
    const predictionInfo = form.getFieldValue('predictionInfo')
    const derievedEndDate = predictionInfo[currentField]?.endDate
    const {
      predictionDetails: [{ startDate, ...rest }],
      ...restOfObj
    } = predictionData
    const newPredictionData = {
      ...restOfObj,
      predictionDetails: [
        {
          ...rest,
          startDate: derivedStartDate
            ? format(new Date(derivedStartDate), DATE_FORMATS.dd_MMM_yyyy)
            : null,
          endDate: derievedEndDate
            ? format(new Date(derievedEndDate), DATE_FORMATS.dd_MMM_yyyy)
            : null,
        },
      ],
    }
    setLastPredictionData(newPredictionData)
  }
  const fetchLatestPredictionData = useCallback(async () => {
    setIsFetchingPredictionData(true)

    const lastPredictionData = await getLatestPredictionOfProject(
      todoProjectId || projectId
    )
    lastPredictionData?.predictionDetails?.length &&
      setPredictionsDate(lastPredictionData, 0)

    setIsFetchingPredictionData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, todoProjectId])

  useEffect(() => {
    fetchLatestPredictionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, todoProjectId])
  useEffect(() => {
    if (
      lastPredictionData?.predictionDetails &&
      lastPredictionData?.predictionDetails[0]
    ) {
      calculateDatesForTodo()
    }
    return () => {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPredictionData])

  /**
   * This helper function receives current field id,selected start date and day difference as arguments and sets end date in form instance
   */
  const setEndDateByInvoiceCycle = (
    currentField,
    startDate,
    dayDifference,
    form
  ) => {
    const invoiceOrPredictionList = form.getFieldValue('predictionInfo')
    const derievedEndDate = new Date(startDate)
    derievedEndDate.setDate(derievedEndDate.getDate() + dayDifference)
    if (invoiceOrPredictionList[currentField]) {
      invoiceOrPredictionList[currentField].endDate = moment(
        derievedEndDate,
        DATE_FORMATS.DD_MMM_YYYY
      )
    }

    form.setFieldsValue(invoiceOrPredictionList)
  }
  const calculateDatesForTodo = useCallback(() => {
    onDateChange(
      moment(lastPredictionData?.predictionDetails[0]?.startDate),
      null,
      ['', 'startDate'],
      0
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPredictionData])

  const updateValue = () => {
    form.setFieldsValue({
      projectName: lastPredictionData
        ? lastPredictionData?.projectName
        : predictionDetails?.projectName,
      projectModel: lastPredictionData
        ? lastPredictionData?.projectModel
        : predictionDetails?.projectModel,
    })
  }

  const onFinish = async (values) => {
    setFormDisabled(true)
    const amountValidationData = []
    const startDateValidationData = []
    const endDateValidationData = []
    const existingPredictionsData = []
    const sameStartDatePredictionIds = []
    const validationMessagesForIncorrectDateRange = []
    const today = dateFormat(new Date(), DATE_FORMATS.dd_mmm_yyyy)
    let predictionId = 0
    let submitCounter = 0
    const projectData = {
      projectName: predictionDetails?.projectName,
      projectModel: predictionDetails?.projectModel,
      csm: predictionDetails?.csm,
    }
    setItemInLocalStorage('projectData', projectData)
    // cheking normal date and amount validation before submitting form and displaying warnings in module
    values.predictionInfo.forEach((detailItem, detailIndex) => {
      const currentFieldStartDateObj = new Date(detailItem.startDate)
      const currentFieldEndDateObj = new Date(detailItem.endDate)
      const todayDateObj = new Date(today)
      if (!isConfirmedByUser && detailItem.predictedAmount > 100000) {
        amountValidationData.push({
          fieldId: detailIndex,
          message: `The amount entered ${
            detailItem.predictedAmount
          } in Prediction- ${
            detailIndex + 1
          } is more than 1 lakh, Do you want to continue with this amount? `,
        })
      }
      setAmountValidation(amountValidationData)

      const checkIfDatesHaveIncorrectRange = (requiredDayDifference) => {
        const actualDayDifference =
          (currentFieldEndDateObj - currentFieldStartDateObj) / 86400000
        if (actualDayDifference !== requiredDayDifference) {
          !isTMretainerPrepaidModel &&
            validationMessagesForIncorrectDateRange.push({
              fieldId: detailIndex,
              message: `The start date and end date are not according to invoice cycle in Prediction-${
                detailIndex + 1
              }. Diffrence between start date and end date should be ${requiredDayDifference} according to invoice cycle '${
                projectDetails?.cycle || lastPredictionData?.cycle
              }'. `,
            })
        }
      }

      switch (
        !isConfirmedByUser &&
        (projectDetails?.cycle || lastPredictionData?.cycle)
      ) {
        // we get date diffenrece in mili seconds , so multiply with 24*60*60*1000= 86400000
        case 'Monthly':
          checkIfDatesHaveIncorrectRange(dayDifference)
          break

        case 'Weekly':
          checkIfDatesHaveIncorrectRange(6)
          break

        case 'BiWeekly':
          checkIfDatesHaveIncorrectRange(13)
          break

        case '15-Days':
          checkIfDatesHaveIncorrectRange(14)
          break
        default:
      }

      setIncorrectDateRangeValidation(validationMessagesForIncorrectDateRange)
      if (!isConfirmedByUser && currentFieldStartDateObj < todayDateObj) {
        startDateValidationData.push({
          fieldId: detailIndex,
          message: `The Start Date selected in Prediction- ${
            detailIndex + 1
          } is from past, Do you wish to continue with past data?`,
        })
      }
      setStartDateValidation(startDateValidationData)
      if (!isConfirmedByUser && currentFieldEndDateObj < todayDateObj) {
        endDateValidationData.push({
          fieldId: detailIndex,
          message: `The End Date selected in Prediction- ${
            detailIndex + 1
          } is from past , Do you wish to continue with past data?`,
        })
      }
      setEndDateValidation(endDateValidationData)
    })
    values.predictionInfo.forEach(async (detailItem, detailIndex) => {
      const currentFieldStartDate = dateFormat(
        detailItem.startDate,
        DATE_FORMATS.dd_mmm_yyyy
      )
      const currentFieldEndDate = dateFormat(
        detailItem.endDate,
        DATE_FORMATS.dd_mmm_yyyy
      )
      const fetchedExistingPredictions =
        await getExistingPredictionForGivenDates(
          projectId,
          currentFieldStartDate,
          currentFieldEndDate
        )
      fetchedExistingPredictions?.length &&
        fetchedExistingPredictions.forEach((item) => {
          // checking that only unique prediction items get pushed into the list
          if (!sameStartDatePredictionIds.includes(item.predictionId)) {
            sameStartDatePredictionIds.push(item.predictionId)
            existingPredictionsData.push(item)
          }
        })
      const dataForTable = existingPredictionsData?.map((item, index) => {
        return {
          key: index,
          id: index,
          url: `${currentPageBaseUrl}/existingprediction`,
          startdate: item.startDate || '-',
          enddate: item.endDate || '-',
        }
      })
      setItemInLocalStorage('existingPredictionsData', existingPredictionsData)
      setExistingPredictions(dataForTable)
      // if there is validation data, then show warning and stop code execution
      if (
        !isConfirmedByUser &&
        (amountValidationData?.length ||
          startDateValidationData?.length ||
          endDateValidationData?.length ||
          existingPredictionsData?.length ||
          sameStartDatePredictionIds?.length ||
          validationMessagesForIncorrectDateRange?.length)
      ) {
        setIsModalOpen(true)
        setFormDisabled(false)
        return
      }
      // submit counter to get assurance that the form gets submitted only once
      if (submitCounter === 0) {
        const newPredictionInfo = values.predictionInfo.map(
          (detailItem, detailIndex) => {
            const predictionDeatilsObject = { ...detailItem }
            // data mapping before sending data
            return {
              ...predictionDeatilsObject,
              predictionId,
              startDate: dateFormat(
                predictionDeatilsObject.startDate,
                DATE_FORMATS.dd_mmm_yyyy
              ),
              endDate:
                predictionDeatilsObject.endDate &&
                dateFormat(
                  predictionDeatilsObject.endDate,
                  DATE_FORMATS.dd_mmm_yyyy
                ),
              receivedMonth: dateFormat(
                predictionDeatilsObject.receivedMonth,
                DATE_FORMATS.mmm_yyyy
              ),
            }
          }
        )
        const data = {
          ...values,
          projectId: lastPredictionData
            ? lastPredictionData?.projectId
            : projectId,
          cycle: lastPredictionData?.cycle,
          predictionDetails: newPredictionInfo,
        }
        delete data.predictionInfo
        submitCounter++
        const response = await addMultiplePredictions(data)
        if (response?.status === STATUS.ok) {
          toastNotify(TOAST.success, response?.data?.message)
          setIsConfirmedByUser(false)
          setIsModalOpen(false)
          setAmountValidation([])
          setEndDateValidation([])
          setStartDateValidation([])
          setExistingPredictions([])
          setFormDisabled(false)
          setIsFormDataChanged(false)
          form.resetFields()
          if (fetchTodoList) {
            fetchTodoList()
          }
          onClose()
          fetchLatestPredictionData()
          dispatch(getPredictionDetailsById(projectId))
          removeItemFromLocalStorage('existingPredictionsData')
        } else {
          toastNotify(
            TOAST.error,
            response?.data?.message ??
              TOASTIFY_MESSAGES.failedAddPredictionMessage
          )
          setIsConfirmedByUser(false)
          setFormDisabled(false)
        }
        return response
      }
    })
  }

  const onFinishFailed = () => {
    toastNotify(TOAST.error, TOASTIFY_MESSAGES.submitFailedMessage)
  }
  const lastday = function (y, m) {
    return new Date(y, m + 1, 0)
  }

  const onDateChange = (date, dateString, name, currentField) => {
    const dateObj = new Date(date)
    const lastDate = lastday(dateObj.getFullYear(), dateObj.getMonth())
    const dateDifferenceInDays = (lastDate - dateObj) / 86400000
    if (!isTMretainerPrepaidModel) {
      switch (name[1]) {
        case dateVariables?.START_DATE:
          switch (projectDetails?.cycle || lastPredictionData?.cycle) {
            case invoiceCycles.MONTHLY:
              if (dateObj.getDate() === 1) {
                const difference = Math.ceil(dateDifferenceInDays)
                setDayDifference(difference)
                setEndDateByInvoiceCycle(currentField, date, difference, form)
              } else {
                setDayDifference(lastDate?.getDate() - 1)
                setEndDateByInvoiceCycle(
                  currentField,
                  date,
                  lastDate?.getDate() - 1,
                  form
                )
              }
              break

            case invoiceCycles.WEEKLY:
              setEndDateByInvoiceCycle(currentField, date, 6, form)
              break

            case invoiceCycles.BI_WEEKLY:
              setEndDateByInvoiceCycle(currentField, date, 13, form)
              break

            case invoiceCycles.FIFTEEN_DAYS:
              setEndDateByInvoiceCycle(currentField, date, 14, form)
              break
            case invoiceCycles.FOUR_WEEKLY:
              setEndDateByInvoiceCycle(currentField, date, 27, form)
              break
            default:
              setEndDateByInvoiceCycle(currentField, date, 13, form)
          }
          break
        default:
      }
    }
  }

  const handleModalOk = useCallback(() => {
    setIsConfirmedByUser(true)
    setIsModalOpen(false)
    form.submit()
  }, [form])

  const handleModalCancel = () => {
    setIsModalOpen(false)
    setIsConfirmedByUser(false)
    setFormDisabled(false)
    setAmountValidation([])
    setStartDateValidation([])
    setEndDateValidation([])
    setExistingPredictions([])
  }

  function handlePopupConfirm() {
    form.resetFields()
    setIsPopupVisible(false)
    setIsFormDataChanged(false)
  }

  return (
    <>
      <Form
        layout="vertical"
        className="prediction"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        disabled={formDisabled}
        onFieldsChange={() => setIsFormDataChanged(true)}
      >
        <Spin spinning={isFetchingPredictionData || false}>
          <ProjectDetailField
            projectName={
              lastPredictionData
                ? lastPredictionData?.projectName
                : predictionDetails?.projectName
            }
            projectModel={
              lastPredictionData
                ? lastPredictionData?.projectModel
                : predictionDetails?.projectModel
            }
            payModel={
              lastPredictionData
                ? lastPredictionData?.payModel
                : predictionDetails?.payModel
            }
            updateValue={updateValue}
          />
          <div className="invoice-prediction-details">
            <div className="invoice-details">
              <Form.List name={'predictionInfo'} initialValue={[{}]}>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field) => (
                        <React.Fragment key={field.key}>
                          <Row justify="space-between">
                            <Col>
                              <h3>{`Prediction-${field.name + 1} Details`}</h3>
                            </Col>
                            <Col>
                              <Button
                                className="detete-icon"
                                title={'Delete Rate'}
                                onClick={() => {
                                  remove(field.name)
                                }}
                                icon={<DeleteIcon />}
                              />
                            </Col>
                          </Row>

                          <Row gutter={40}>
                            <>
                              <Col sm={12} md={12} lg={12}>
                                <SelectField
                                  name={[field.name, 'accuracy']}
                                  label="Accuracy"
                                  placeholder={
                                    PLACEHOLDER_MESSAGES.accuracyMessage
                                  }
                                  type="text"
                                  options={accuracyOptions}
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        VALIDATION_MESSAGES.accuracyMessage,
                                    },
                                  ]}
                                  existingValue={
                                    lastPredictionData?.predictionDetails &&
                                    lastPredictionData?.predictionDetails[0]
                                      ?.accuracy
                                  }
                                />
                              </Col>
                              <Col sm={12} md={12} lg={12}>
                                <InputField
                                  name={[field.name, 'predictedAmount']}
                                  label="Amount"
                                  required={true}
                                  placeholder={
                                    PLACEHOLDER_MESSAGES.amountMessage
                                  }
                                  type="text"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        VALIDATION_MESSAGES.amountMessage,
                                    },
                                    {
                                      pattern: new RegExp(/^\d+$/),
                                      message:
                                        VALIDATION_MESSAGES.amountPatternMessage,
                                    },
                                  ]}
                                  prefix={currencySymbol}
                                  existingValue={
                                    lastPredictionData?.predictionDetails &&
                                    lastPredictionData?.predictionDetails[0]
                                      ?.predictedAmount
                                  }
                                />
                              </Col>
                            </>
                            <>
                              <Col sm={12} md={12} lg={12}>
                                <DateField
                                  name={[field.name, dateVariables.START_DATE]}
                                  label="Start Date"
                                  type="text"
                                  placeholder={
                                    PLACEHOLDER_MESSAGES.startDateMessage
                                  }
                                  onDateChange={(date, dateString, name) =>
                                    onDateChange(
                                      date,
                                      dateString,
                                      name,
                                      field.name
                                    )
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        VALIDATION_MESSAGES.startDateMessage,
                                    },
                                  ]}
                                  existingValue={
                                    lastPredictionData?.predictionDetails &&
                                    lastPredictionData?.predictionDetails[0]
                                      ?.startDate
                                  }
                                />
                              </Col>
                              <Col sm={12} md={12} lg={12}>
                                <DateField
                                  name={[field.name, dateVariables.END_DATE]}
                                  label="End Date"
                                  type="text"
                                  placeholder={
                                    PLACEHOLDER_MESSAGES.endDateMessage
                                  }
                                  onDateChange={onDateChange}
                                  // rules={
                                  //   isTMretainerPrepaidModel
                                  //     ? []
                                  //     : [
                                  //         {
                                  //           required: true,
                                  //           message:
                                  //             VALIDATION_MESSAGES.endDate,
                                  //         },
                                  //       ]
                                  // }
                                  disabledDate={(currentDate) => {
                                    // getting current field data to compare EndDate

                                    const currentFieldData = form.getFieldValue(
                                      'invoiceDetails'
                                    )
                                      ? form.getFieldValue('invoiceDetails')
                                      : form.getFieldValue('predictionInfo')

                                    const currentFieldStartDate =
                                      currentFieldData[field.name]?.startDate
                                    return (
                                      currentDate &&
                                      currentFieldStartDate &&
                                      currentDate < currentFieldStartDate
                                    )
                                  }}
                                  existingValue={
                                    lastPredictionData?.predictionDetails &&
                                    lastPredictionData?.predictionDetails[0]
                                      ?.endDate
                                  }
                                />
                              </Col>
                            </>
                            <>
                              <Col md={12}>
                                <DateField
                                  name={[field.name, 'receivedMonth']}
                                  label="Received Month"
                                  type="text"
                                  placeholder={
                                    PLACEHOLDER_MESSAGES.receivedMonthMessage
                                  }
                                  onDateChange={onDateChange}
                                  required={true}
                                  rules={receivedMonthRule}
                                  existingValue={
                                    lastPredictionData?.predictionDetails &&
                                    lastPredictionData?.predictionDetails[0]
                                      ?.receivedMonth
                                  }
                                  picker={DATE_PICKER.month}
                                />
                              </Col>
                              <Col md={12}>
                                <SelectField
                                  name={[field.name, 'submissionType']}
                                  label="Submission Type"
                                  placeholder={
                                    PLACEHOLDER_MESSAGES.submissionTypeMessage
                                  }
                                  type="text"
                                  options={submissionTypeOptions}
                                  isFeatured={true}
                                  existingValue={
                                    lastPredictionData?.predictionDetails &&
                                    lastPredictionData?.predictionDetails[0]
                                      ?.submissionType
                                  }
                                  required
                                  rules={subMissionTypeRule}
                                />
                              </Col>
                            </>
                            <Col md={24}>
                              <SelectField
                                name={[field.name, 'associatedProjectNames']}
                                label="ProjectName"
                                className={'multiple-projects'}
                                placeholder={
                                  PLACEHOLDER_MESSAGES.associatedProjectNamesMessage
                                }
                                type="text"
                                options={projectNameOptions}
                                mode={'multiple'}
                                tagRender={ProjectNamestagRender}
                                existingValue={
                                  lastPredictionData?.predictionDetails &&
                                  lastPredictionData?.predictionDetails[0]
                                    ?.associatedProjectNames
                                }
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      ))}
                      <Button
                        onClick={() => {
                          add()
                        }}
                        type="link"
                        className="add-package"
                        label={`Add another prediction`}
                        icon={<AddPackage />}
                      />
                    </div>
                  )
                }}
              </Form.List>
              <div className="drawer-button btn-group">
                <PopupConfirm
                  onConfirm={handlePopupConfirm}
                  onCancel={() => {
                    setIsPopupVisible(false)
                  }}
                  cancelText={NO}
                  okText={YES}
                  title={'Do you want to discard the changes?'}
                >
                  <Button
                    danger
                    label="Cancel"
                    disabled={!lastPredictionData && !isFormDataChanged}
                    onClick={() => setIsPopupVisible((prevState) => !prevState)}
                  />
                </PopupConfirm>
                <Button
                  type="primary"
                  label="Save"
                  htmlType="submit"
                  disabled={!lastPredictionData && !isFormDataChanged}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Form>

      <CustomizeModal
        title="Warning !"
        centered={true}
        open={isModalOpen}
        size={900}
        handleModalOk={handleModalOk}
        onModalCancel={handleModalCancel}
        onClose={handleModalCancel}
        setShowSearchField={setIsModalOpen}
        wrapClassName="exist-data-modal"
        okText="Yes, Continue"
        cancelText="No"
        closable={true}
      >
        <div className="exist-data-content">
          {incorrectDateRangeValidation?.map((item) => (
            <p className="data-message" key={item.message}>
              {item.message}
            </p>
          ))}
          {incorrectDateRangeValidation?.length > 0 && (
            <DividerLine className="divider" />
          )}
          {amountValidation?.map((item) => (
            <p className="data-message" key={item.message}>
              {item.message}
            </p>
          ))}
          {amountValidation?.length > 0 && <DividerLine className="divider" />}
          {startDateValidation?.map((item) => (
            <p className="data-message" key={item.message}>
              {item.message}
            </p>
          ))}
          {startDateValidation?.length > 0 && (
            <DividerLine className="divider" />
          )}
          {endDateValidation?.map((item) => (
            <p className="data-message" key={item.message}>
              {item.message}
            </p>
          ))}
          {/* table for predictions or invoices having start date or end date in range of entered sart date and end date by user */}
          {existingPredictions.length > 0 && (
            <DataTable
              dataSource={existingPredictions}
              rowKey={(record) => record?.key}
              tableLayout="fixed"
              pagination={false}
              columns={existingPredictionsColumns}
            />
          )}
        </div>
      </CustomizeModal>
    </>
  )
}
export default AddMultiplePredictions
