import React from 'react'
import { DatePicker, Form, Input, Select } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import {
  filteredOptions,
  getColumnSearchProps,
} from '../../utils/helperFunctions/helperFunctions'
import { DataTable } from '../index'
import {
  DATE_FORMATS,
  DATE_PICKER,
  showReceivedDateAndReceivedMonthFields,
} from '../../utils/constants/constant'
const { Option } = Select

const ReceivedInvoiceTable = ({
  dataSource,
  onAmountFieldChange,
  onMonthChange,
  onDateChange,
  onInvoiceStatusChange,
}) => {
  const { invoiceStatusOptions, projectNameOptions } = useSelector(
    staticOptionsSelector
  )
  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      filters: filteredOptions('text', projectNameOptions),
      filterSearch: true,
      onFilter: (value, record) => record.projectName.indexOf(value) === 0,
      ellipsis: true,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      ellipsis: true,
      render: (invoiceAmount, record) => {
        return (
          <Form
            fields={[
              {
                name: [`${record?.invoiceId}invoiceAmount`],
                value: invoiceAmount,
              },
            ]}
          >
            <Form.Item
              name={`${record?.invoiceId}invoiceAmount`}
              className="text-field"
              initialValue={invoiceAmount}
            >
              <Input
                value={invoiceAmount}
                onChange={(e) =>
                  onAmountFieldChange(record, e.target.value, 'invoiceAmount')
                }
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                prefix="$"
                type="number"
                allowClear
              />
            </Form.Item>
          </Form>
        )
      },
    },
    {
      title: 'Received Amount',
      dataIndex: 'receivedAmount',
      ellipsis: true,
      render: (receivedAmount, record) => {
        return (
          <Form
            fields={[
              {
                name: [`${record?.invoiceId}receivedAmount`],
                value: receivedAmount,
              },
            ]}
          >
            <Form.Item
              name={`${record?.invoiceId}receivedAmount`}
              className="text-field"
              initialValue={receivedAmount}
            >
              <Input
                onChange={(e) =>
                  onAmountFieldChange(record, e.target.value, 'receivedAmount')
                }
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                value={receivedAmount}
                prefix="$"
                type="number"
                step={0.01}
                allowClear
              />
            </Form.Item>
          </Form>
        )
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      ...getColumnSearchProps('startDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      render: (start_date) =>
        start_date || <div className="text-center">-</div>,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      ...getColumnSearchProps('endDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      render: (end_date) => end_date || <div className="text-center">-</div>,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      ...getColumnSearchProps('invoiceDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
    },
    {
      title: 'Receive Month',
      dataIndex: 'receivedMonth',
      ...getColumnSearchProps('invoiceDate', DATE_FORMATS.MMM_YYYY, 'month'),
      ellipsis: true,
      render: (receivedMonth, record) => (
        <Form.Item className="text-field">
          <DatePicker
            className="date-field"
            defaultValue={
              receivedMonth
                ? moment(receivedMonth, DATE_FORMATS.MMM_YYYY)
                : moment(new Date(), DATE_FORMATS.MMM_YYYY)
            }
            value={
              receivedMonth
                ? moment(receivedMonth, DATE_FORMATS.MMM_YYYY)
                : moment(new Date(), DATE_FORMATS.MMM_YYYY)
            }
            disabled={
              !showReceivedDateAndReceivedMonthFields.includes(
                record?.invoiceStatus
              )
            }
            onChange={(_, datestring) => {
              onMonthChange(datestring, record)
            }}
            format={DATE_FORMATS.MMM_YYYY}
            picker={DATE_PICKER.month}
            allowClear
          />
        </Form.Item>
      ),
    },
    {
      title: 'Receive Date',
      dataIndex: 'receivedDate',
      ...getColumnSearchProps('receivedDate', DATE_FORMATS.DD_MMM_YYYY),
      ellipsis: true,
      render: (receivedDate, record) => (
        <Form.Item className="text-field">
          <DatePicker
            className="date-field text-field"
            defaultValue={
              receivedDate
                ? moment(receivedDate, DATE_FORMATS.DD_MMM_YYYY)
                : moment(new Date(), DATE_FORMATS.DD_MMM_YYYY)
            }
            value={
              receivedDate
                ? moment(receivedDate, DATE_FORMATS.DD_MMM_YYYY)
                : moment(new Date(), DATE_FORMATS.DD_MMM_YYYY)
            }
            disabled={
              !showReceivedDateAndReceivedMonthFields.includes(
                record?.invoiceStatus
              )
            }
            onChange={(_, datestring) => {
              onDateChange(datestring, record)
            }}
            format={DATE_FORMATS.DD_MMM_YYYY}
            allowClear
          />
        </Form.Item>
      ),
    },
    {
      title: 'Invoice Status',
      dataIndex: 'invoiceStatus',
      filters: filteredOptions('text', invoiceStatusOptions),
      onFilter: (value, record) => record.invoiceStatus.indexOf(value) === 0,
      ellipsis: true,
      render: (status, record) => (
        <Form.Item className="select-field text-field payment-status">
          <Select
            value={status}
            onChange={(value) => onInvoiceStatusChange(record, value)}
          >
            {invoiceStatusOptions?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      ),
    },
  ]
  return (
    <DataTable
      className="invoice-data"
      dataSource={dataSource}
      rowKey={(record) => record?.invoiceId}
      pagination={false}
      columns={columns}
      scroll={{ x: 1024 }}
    />
  )
}
export default ReceivedInvoiceTable
