import React, { useRef, useState, useEffect } from 'react'
import { Form, Spin } from 'antd'
import moment from 'moment'
import './ReceivedInvoice.scss'
import {
  Button,
  DrawerModule,
  CustomizeRenderEmpty,
  PopupConfirm,
} from '../index'
import {
  editReceivedInvoices,
  fetchReceivedInvoices,
  getPredictionDetailsById,
} from '../../utils/api/predictionAndInvoiceServices'
import { useDispatch, useSelector } from 'react-redux'
import {
  DATE_FORMATS,
  STATUS,
  TOAST,
  showReceivedDateAndReceivedMonthFields,
} from '../../utils/constants/constant'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import ReceivedInvoiceTable from '../ReceivedInvoiceTable/ReceivedInvoiceTable'
import { YES, NO } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import SearchInvoiceField from '../SearchInvoiceField/SearchInvoiceField'
import { suggestionsOptionsApiService } from '../../utils/api/staticOptionsServices'

const ReceivedInvoice = ({ open, onClose }) => {
  const [dataSource, setDataSource] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [tempDataSource, setTempDataSource] = useState([])
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [autoSearchOptions, setAutoSearchOptions] = useState([])
  const inputRef = useRef(null)
  const { projectId } = useSelector((state) => state.projectId)
  const { invoiceNumberOptions } = useSelector(staticOptionsSelector)
  const [searchFormInstance] = Form.useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    inputRef.current.input.value = null
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // onClose dependency reset invoice number field
  }, [onClose])
  const onSearchInvoiceNumber = async (invoiceNumber) => {
    setIsLoading(true)
    const response = await fetchReceivedInvoices(invoiceNumber)
    if (response?.status === STATUS.ok) {
      const data = response?.data?.data
      setDataSource(data)
      setTempDataSource(data)
    }
    setIsLoading(false)
  }

  const onCloseReceiveModule = () => {
    projectId && dispatch(getPredictionDetailsById(projectId))
    onClose()
    setDataSource(null)
    setIsLoading(false)
  }
  const onMonthChange = (dateString, record) => {
    const updatedDatasource = dataSource.map((data) => {
      if (data.invoiceId === record.invoiceId) {
        return {
          ...data,
          receivedMonth: dateString,
        }
      } else {
        return {
          ...data,
        }
      }
    })
    setDataSource(updatedDatasource)
  }
  const onDateChange = (dateString, record) => {
    const updatedDatasource = dataSource.map((data) => {
      if (data.invoiceId === record.invoiceId) {
        return {
          ...data,
          receivedDate: dateString,
          receivedMonth: moment(dateString).format(DATE_FORMATS.MMM_YYYY),
        }
      } else {
        return {
          ...data,
        }
      }
    })
    setDataSource(updatedDatasource)
  }
  const onInvoiceStatusChange = (record, value) => {
    const updatedDatasource = dataSource?.map((data) => {
      if (data.invoiceId === record.invoiceId) {
        if (!showReceivedDateAndReceivedMonthFields.includes(value)) {
          return {
            ...data,
            receivedAmount: 0,
            invoiceStatus: value,
          }
        } else {
          switch (value) {
            case 'Received': {
              // check amount fields(must be same) when Received status selected
              if (data?.receivedAmount !== data?.invoiceAmount) {
                toastNotify(TOAST.warning, TOASTIFY_MESSAGES.sameAmountMessage)
                return {
                  ...data,
                }
              }
              return {
                ...data,
                invoiceStatus: value,
                receivedDate: moment(new Date()).format(
                  DATE_FORMATS.DD_MMM_YYYY
                ),
                receivedMonth: moment(new Date()).format(DATE_FORMATS.MMM_YYYY),
              }
            }
            case 'Deposit Settlement': {
              return {
                ...data,
                receivedAmount: 0,
                invoiceStatus: value,
                receivedDate: moment(new Date()).format(
                  DATE_FORMATS.DD_MMM_YYYY
                ),
                receivedMonth: moment(new Date()).format(DATE_FORMATS.MMM_YYYY),
              }
            }
            case 'Partial Received': {
              if (data?.receivedAmount === 0) {
                toastNotify(
                  TOAST.warning,
                  TOASTIFY_MESSAGES.receivedAmountRequireMessage
                )
                return {
                  ...data,
                }
              }
              return {
                ...data,
                invoiceStatus: value,
                receivedDate: moment(new Date()).format(
                  DATE_FORMATS.DD_MMM_YYYY
                ),
                receivedMonth: moment(new Date()).format(DATE_FORMATS.MMM_YYYY),
              }
            }
            default: {
              return {
                ...data,
              }
            }
          }
        }
      } else {
        return {
          ...data,
        }
      }
    })
    setDataSource(updatedDatasource)
  }
  const saveClickHandler = async () => {
    // check Inovoice amount field must be grater than or equal to Received Amount
    const errorfields = dataSource?.filter((data) => {
      if (data?.invoiceAmount < data?.receivedAmount) {
        toastNotify(
          TOAST.warning,
          TOASTIFY_MESSAGES.receivedInvoiceAmountMessage(data)
        )
        return {
          ...data,
        }
      } else {
        return false
      }
    })
    if (errorfields?.length > 0) {
      return
    }
    const response = await editReceivedInvoices(dataSource)
    if (response?.code === STATUS.ok) {
      toastNotify(TOAST.success, TOASTIFY_MESSAGES.updateInvoiceMessage)
    } else {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.wentWrongMessage)
    }
    setDataSource(response?.data)
    setTempDataSource(response?.data)
  }
  const cancleClickHandler = () => {
    setDataSource(tempDataSource)
    setIsPopupVisible(false)
  }
  const onAmountFieldChange = (record, value, field) => {
    const updatedDatasource = dataSource?.map((data) => {
      if (data.invoiceId === record.invoiceId) {
        // check amount fields(Invoice Amount always equal or grater than received amount) when amount fields change
        if (
          field === 'invoiceAmount' &&
          Number(value) < record?.receivedAmount
        ) {
          toastNotify(TOAST.warning, TOASTIFY_MESSAGES.lessInvoiceAmountMessage)
        } else if (
          field === 'receivedAmount' &&
          Number(value) > record?.invoiceAmount
        ) {
          toastNotify(
            TOAST.warning,
            TOASTIFY_MESSAGES.greaterReceivedAmountMessage
          )
          return {
            ...data,
          }
        }

        if (
          field === 'invoiceAmount'
            ? Number(value) === record?.receivedAmount
            : Number(value) === record?.invoiceAmount
        ) {
          return {
            ...data,
            invoiceStatus: 'Received',
            [field]: Number(value),
            receivedDate: moment(new Date()).format(DATE_FORMATS.DD_MMM_YYYY),
            receivedMonth: moment(new Date()).format(DATE_FORMATS.MMM_YYYY),
          }
        } else {
          return {
            ...data,
            invoiceStatus: 'Partial Received',
            [field]: Number(value),
            receivedDate: moment(new Date()).format(DATE_FORMATS.DD_MMM_YYYY),
            receivedMonth: moment(new Date()).format(DATE_FORMATS.MMM_YYYY),
          }
        }
      } else {
        return {
          ...data,
        }
      }
    })
    setDataSource(updatedDatasource)
  }
  return (
    <DrawerModule
      className="invoice-received-wrapper"
      size="100%"
      title={'Received Invoices'}
      open={open}
      onClose={onCloseReceiveModule}
      closable={!isPopupVisible}
    >
      <Form
        form={searchFormInstance}
        onFinish={(value) => {
          if (!value.searchInvoice) return setDataSource(null)
          onSearchInvoiceNumber(value.searchInvoice)
        }}
      >
        <SearchInvoiceField
          label={'Search Invoice'}
          searchFormInstance={searchFormInstance}
          onClear={() => setDataSource(null)}
          invoiceNumberOptions={invoiceNumberOptions}
          inputRef={inputRef}
          onChange={async (value) => {
            const invoiceNumbers = await suggestionsOptionsApiService(
              'invoiceNumber',
              value
            )
            setAutoSearchOptions(invoiceNumbers)
          }}
          autoSearchOptions={autoSearchOptions}
          setAutoSearchOptions={setAutoSearchOptions}
        />
      </Form>

      <Spin spinning={isLoading}>
        {dataSource?.length > 0 ? (
          <>
            <Form onFinish={saveClickHandler}>
              <div className="receive-invoice">
                <div className="receive-invoice-wrap">
                  <ReceivedInvoiceTable
                    dataSource={dataSource}
                    onAmountFieldChange={onAmountFieldChange}
                    onMonthChange={onMonthChange}
                    onDateChange={onDateChange}
                    onInvoiceStatusChange={onInvoiceStatusChange}
                  />
                </div>
              </div>
              <div className="drawer-button btn-group">
                <PopupConfirm
                  onConfirm={cancleClickHandler}
                  onCancel={() => setIsPopupVisible(false)}
                  cancelText={NO}
                  okText={YES}
                  title={'Do you want to discard the changes?'}
                >
                  <Button
                    disabled={tempDataSource === dataSource}
                    danger
                    label="Cancel"
                    onClick={() => setIsPopupVisible(!isPopupVisible)}
                  />
                </PopupConfirm>
                <Button
                  disabled={tempDataSource === dataSource}
                  type="primary"
                  label="Save"
                  htmlType="submit"
                />
              </div>
            </Form>
          </>
        ) : (
          dataSource?.length === 0 && (
            <CustomizeRenderEmpty
              setDataSource={setDataSource}
              inputRef={inputRef}
            />
          )
        )}
      </Spin>
    </DrawerModule>
  )
}

export default ReceivedInvoice
