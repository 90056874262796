import React, { useEffect, useState } from 'react'
import { Divider, Table } from 'antd'

import './DetailsModal.scss'
import {
  hoursBreakColumn,
  leavesBreakColumn,
  onTimeColumns,
} from '../TimeSheetModule/TimeSheetColumns'
import CustomizeModal from '../Modal/Modal'
import Button from '../Button/Button'
import DataTable from '../DataTable/DataTable'
import LeavesTable from './LeavesTable'
import { reCalculateLeavesService } from '../../utils/api/timesheetServices'
import { STATUS, TOAST } from '../../utils/constants/constant'
import {
  setTimeSheetsData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'

const DetailsModal = ({
  showDetailsModal,
  setShowDetailsModal,
  modalType,
  detailData,
  startDate,
  endDate,
  timesheetStartDate,
  timesheetEndDate,
  timesheetNameFormat,
  showDeductionColumn,
  projectName
}) => {
  const [modalTableData, setModalTableData] = useState([])
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)
  const dispatch = useDispatch()

  // Function will add approved leaves days to leaveTaken & move leave's object from pending to leaveDetails
  const recalculateLeaves = async (totalApprovedDays, updatedLeaves) => {
    const zohoEmployeeMetadata = JSON.parse(JSON.stringify(detailData))
    // replace leaveTaken to totalApprovedDays

    zohoEmployeeMetadata.userLeaveData.leaveTaken = totalApprovedDays

    // Update leaveDetails
    zohoEmployeeMetadata.userLeaveData.leaveDetails = updatedLeaves

    // call service to calculate hours & leave as approved pending leave
    calculateEmployeeHours(zohoEmployeeMetadata)
  }
  const calculateEmployeeHours = async (zohoEmployeeMetadata) => {
    try {
      const response = await reCalculateLeavesService(
        projectName,
        startDate,
        endDate,
        timesheetStartDate,
        timesheetEndDate,
        zohoEmployeeMetadata
      )
      if (response?.status === STATUS.ok) {
        const calculatedEmployeesData = response?.data?.data
        const dataToUpdate = JSON.parse(
          JSON.stringify(timeSheetsData[timesheetNameFormat])
        )
        const updatedZohoEmployeeMetadataList =
          dataToUpdate?.zohoEmployeeMetadataList?.map((metaData) => {
            if (metaData?.userId === calculatedEmployeesData?.userId) {
              return calculatedEmployeesData
            }
            return metaData
          })
        const newTimesheetData = {
          ...timeSheetsData,
          [timesheetNameFormat]: {
            ...timeSheetsData[timesheetNameFormat],
            zohoEmployeeMetadataList: updatedZohoEmployeeMetadataList,
          },
        }
        dispatch(setTimeSheetsData(newTimesheetData))
      }
    } catch (error) {
      toastNotify(
        TOAST.error,
        error?.message || TOASTIFY_MESSAGES.somethingWentWrongMessage
      )
    }
  }
  const getDataSourceForTable = () => {
    switch (modalType) {
      case 'leave':
        return detailData?.userLeaveData?.leaveDetails
      case 'billableTasks':
        return detailData?.billableTasks
      case 'nonBillableTasks':
        return detailData?.nonBillableTasks
      case 'onTimeLogs':
        return detailData?.onTimeLogs
      default:
        return []
    }
  }
  useEffect(() => {
    const tableData = getDataSourceForTable()
    setModalTableData(tableData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType, detailData])

  return (
    <CustomizeModal
      title={
        modalType === 'billableTasks'
          ? 'Billable hours details'
          : modalType === 'nonBillableTasks'
          ? 'Non billable hours details'
          : modalType === 'leave'
          ? 'Leave details'
          : 'On-time log details'
      }
      centered={true}
      open={showDetailsModal}
      size={1000}
      handleModalOk={() => {
        setShowDetailsModal(!showDetailsModal)
      }}
      okText={'Ok'}
      closable={false}
      footer={
        <Button
          danger
          label={'OK'}
          onClick={() => {
            setShowDetailsModal(!showDetailsModal)
          }}
        />
      }
    >
      <Divider />
      <div className="hours-table">
        <div>
          <p className="user-name">
            Employee Name : {detailData?.employeeName}
          </p>
        </div>
        {Boolean(
          modalType === 'leave' &&
            detailData?.userLeaveData?.leaveDetails?.length &&
            showDeductionColumn
        ) ? (
          <>
            <Divider />
            <div className="pending-leaves">
              <LeavesTable
                leaveDetails={detailData?.userLeaveData?.leaveDetails}
                recalculateLeaves={recalculateLeaves}
                setShowDetailsModal={setShowDetailsModal}
              />
            </div>
          </>
        ) : (
          <DataTable
            columns={
              modalType === 'billableTasks' || modalType === 'nonBillableTasks'
                ? hoursBreakColumn
                : modalType === 'leave'
                ? leavesBreakColumn
                : onTimeColumns
            }
            scroll={{ y: 300 }}
            dataSource={modalTableData}
            rowKey={(record, index) => index}
            pagination={false}
            summary={(pageData) => {
              let totalNumberOfDays = 0
              let totalHoursSum = 0
              let totalHoursWorked = 0
              pageData.forEach(({ numberOfDays, totalHours, ontimeLogs }) => {
                totalNumberOfDays += Number(numberOfDays) || 0
                totalHoursSum += Number(totalHours) || 0
                totalHoursWorked += Number(ontimeLogs?.hoursWorked) || 0
              })
              return (
                <>
                  <Table.Summary.Row className="bold-text">
                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                    <Table.Summary.Cell className="bold-text">
                      {modalType === 'leave' && totalNumberOfDays?.toFixed(2)}
                      {(modalType === 'billableTasks' ||
                        modalType === 'nonBillableTasks') &&
                        totalHoursSum?.toFixed(2)}
                      {modalType === 'onTimeLogs' &&
                        totalHoursWorked?.toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
          />
        )}
      </div>
    </CustomizeModal>
  )
}

export default DetailsModal
