import { Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactComponent as AddPackage } from '../../assets/icons/plus.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import './OtherDetailsPanel.scss'
import {
  SelectField,
  Button,
  AutoFieldComponent,
  UploadFile,
} from '../../components'
import { renderFeaturedElement } from '../../utils/helperFunctions/helperFunctions'
import { PLACEHOLDER_MESSAGES } from '../../utils/constants/messages'
import {
  accountantRule,
  activeBillingRule,
  leadSourceRule,
  salesManRule,
  secondaryCSMRule,
} from '../../utils/constants/validationRules'
import { YES, NO } from '../../utils/constants/constant'

const OtherDetailsPanel = ({
  csmOptions,
  projectData,
  accountantOptions,
  setShow,
  setDocument,
  show,
  salesPersonOptions,
  setIsValidSize,
  isValidSize,
  leadSourceOptions,
  marketingChannelOptions,
  activeBillingOptions,
  form,
  primaryCsm,
  secondaryCsmList,
  secondaryAccountantList,
  primaryAccountant,
  isDisableAdd,
}) => {
  const [existingSalesman, setExistingSalesman] = useState([])
  const [existingCsm, setExistingCsm] = useState([])
  const [existingAccountants, setExistingAccountants] = useState([])
  const [documentNames, setDocumentNames] = useState(
    projectData?.contractLinks ?? []
  )
  const salesPersons = projectData?.salesPersons
  const csmUsers = projectData?.csmUsers

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData])
  useEffect(() => {
    const existingSalesManData = salesPersons?.map((item) => {
      return { salesperson: item?.id }
    })
    const existingCsmData = secondaryCsmList?.map((item) => {
      return { csm: item?.email }
    })

    const existingAccountants = secondaryAccountantList?.map((item) => {
      return { accountant: item?.email }
    })
    setExistingAccountants(existingAccountants)
    setExistingSalesman(existingSalesManData)
    setExistingCsm(existingCsmData)
    if (existingSalesManData?.length) {
      form.setFieldValue('salesmans', existingSalesManData)
    } else {
      form.setFieldValue('salesmans', [{}])
    }
    form.setFieldValue('secondaryCsm', existingCsmData)
    form.setFieldValue('accountantsList', existingAccountants)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesPersons, secondaryAccountantList, csmUsers, secondaryCsmList])
  useEffect(() => {
    form.setFieldValue('csm', primaryCsm && primaryCsm[0]?.email)
    form.setFieldValue(
      'accountant',
      primaryAccountant && primaryAccountant[0]?.email
    )
  }, [primaryAccountant, primaryCsm, form])

  const fileChangeHandler = (params) => {
    const fileObjs = params.fileList?.map((file) => file?.originFileObj)
    const fileNames = params.fileList?.map((file) => file.name)
    const removedFile = params?.file.status === 'removed' && params?.file.name
    setDocumentNames(fileNames)
    setDocument((prevState) => {
      return {
        addFiles: fileObjs,
        removeFiles: removedFile
          ? [...prevState.removeFiles, removedFile]
          : prevState.removeFiles,
      }
    })
  }
  const beforeUpload = (file) => {
    if (file.size > 10000000) {
      setIsValidSize(false)
    } else {
      setIsValidSize(true)
    }
  }
  const secondaryCsmOptions = renderFeaturedElement(
    csmOptions?.length
      ? csmOptions?.filter((csm) => {
          return csm?.email !== primaryCsm[0]?.email
        })
      : []
  )
  return (
    <>
      <Row gutter={40}>
        <Col lg={24} md={24} sm={24}>
          <UploadFile
            name="contractDocument"
            label="Contract Document"
            placeholder={
              projectData?.contractLink === '-'
                ? PLACEHOLDER_MESSAGES.contractDocumentMessage
                : ''
            }
            docName={
              projectData?.contractLink === '-'
                ? null
                : [`${projectData?.contractLink}`]
            }
            documentNames={documentNames}
            maxCount={5}
            onChange={fileChangeHandler}
            show={show}
            beforeUpload={beforeUpload}
            isValidSize={isValidSize}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="activeBillingFlag"
            label="Active Billing"
            options={activeBillingOptions}
            existingValue={projectData?.activeBillingFlag ? YES : NO}
            value={projectData?.activeBillingFlag ? YES : NO}
            placeholder={PLACEHOLDER_MESSAGES.activeBillingMessage}
            required
            type="text"
            rules={activeBillingRule}
            disabled={true}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="leadSource"
            label="Lead Source"
            options={leadSourceOptions}
            existingValue={
              projectData?.leadSource === '-' ? null : projectData?.leadSource
            }
            placeholder={PLACEHOLDER_MESSAGES.leadSourceMessage}
            type="text"
            required
            rules={leadSourceRule}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="marketingChannel"
            label="Marketing Channel"
            options={marketingChannelOptions}
            existingValue={
              projectData?.channel === '-' ? null : projectData?.channel
            }
            placeholder={PLACEHOLDER_MESSAGES.marketingChannelMessage}
            type="text"
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <AutoFieldComponent
            name="csm"
            label="Primary CSM"
            existingValue={primaryCsm && primaryCsm[0]?.email}
            value={primaryCsm && primaryCsm[0]?.email}
            placeholder={PLACEHOLDER_MESSAGES.primaryCSMMessage}
            disabled={true}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <AutoFieldComponent
            name="accountant"
            label="Primary Accountant"
            options={renderFeaturedElement(accountantOptions)}
            rules={accountantRule}
            filterOption={(input, option) => {
              const accOptions = accountantOptions?.email
                ?.toLowerCase()
                .includes(input?.toLowerCase())
              return accOptions
            }}
            existingValue={
              primaryAccountant?.length && primaryAccountant[0]?.email
            }
            isFeatured={true}
            placeholder={PLACEHOLDER_MESSAGES.primaryAccountantMessage}
            type="text"
            values={0}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.List name="secondaryCsm" initialValue={existingCsm}>
            {(fields, { add, remove }) => {
              return (
                <Row>
                  {fields.map((field, index) => (
                    <Col lg={24} md={24} sm={24} key={index}>
                      <div className="select-field-with-button">
                        <AutoFieldComponent
                          name={[field.name, 'csm']}
                          label={`Secondary CSM ${index + 1}`}
                          options={secondaryCsmOptions}
                          required
                          rules={secondaryCSMRule}
                          value={secondaryCsmList[index]?.email}
                          filterOption={(input, option) => {
                            const csmOptions = option?.label
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                            return csmOptions
                          }}
                          placeholder={`select csm ${index + 1}`}
                          type="text"
                        />
                        <Button
                          className="detete-btn"
                          title={'Delete CSM'}
                          onClick={() => {
                            remove(field.name)
                          }}
                          icon={<DeleteIcon />}
                        />
                      </div>
                    </Col>
                  ))}
                  <Col lg={24} md={24} sm={24} className="sales-btn">
                    <Button
                      onClick={() => add()}
                      type="link"
                      className="add-package"
                      label="Add secondary CSM"
                      icon={<AddPackage />}
                      disabled={isDisableAdd?.secondaryCsm}
                    />
                  </Col>
                </Row>
              )
            }}
          </Form.List>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.List
            name="salesmans"
            initialValue={existingSalesman || [{}]}
            preserve={false}
          >
            {(fields, { add, remove }) => {
              return (
                <Row>
                  {fields.map((field, index) => (
                    <Col lg={24} md={24} sm={24} key={index}>
                      <div className="select-field-with-button">
                        <SelectField
                          name={[field.name, 'salesperson']}
                          label={`Salesman ${index + 1}`}
                          options={salesPersonOptions?.map((option) => ({
                            label: option.name,
                            value: option.id,
                          }))}
                          rules={salesManRule}
                          isFeatured={true}
                          filterOption={(input, option) => {
                            const saleOptions = option?.label
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                            return saleOptions
                          }}
                          placeholder={`${
                            PLACEHOLDER_MESSAGES.salesmanMessage
                          } ${index + 1}`}
                          type="text"
                          values={0}
                        />
                        <Button
                          className="detete-btn"
                          title={'Delete Salesman'}
                          onClick={() => {
                            remove(field.name)
                          }}
                          icon={<DeleteIcon />}
                        />
                      </div>
                    </Col>
                  ))}
                  <Col lg={24} md={24} sm={24} className="sales-btn">
                    <Button
                      onClick={() => {
                        add()
                      }}
                      type="link"
                      className="add-package"
                      label="Add another salesman"
                      icon={<AddPackage />}
                      disabled={isDisableAdd?.salesPersons}
                    />
                  </Col>
                </Row>
              )
            }}
          </Form.List>
        </Col>

        <Col lg={12} md={12} sm={12}>
          <Form.List
            name="accountantsList"
            initialValue={existingAccountants || [{}]}
          >
            {(fields, { add, remove }) => {
              return (
                <Row>
                  {fields.map((field, index) => (
                    <Col lg={24} md={24} sm={24} key={index}>
                      <div className="select-field-with-button">
                        <AutoFieldComponent
                          name={[field.name, 'accountant']}
                          label={`Secondary accountant ${index + 1}`}
                          options={renderFeaturedElement(accountantOptions)}
                          rules={accountantRule}
                          filterOption={(input, option) => {
                            const accOptions = accountantOptions?.email
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                            return accOptions
                          }}
                          isFeatured={true}
                          placeholder={`${
                            PLACEHOLDER_MESSAGES.accountantMessage
                          } ${index + 1}`}
                          type="text"
                          values={0}
                        />
                        <Button
                          className="detete-btn"
                          title={'Delete Accountant'}
                          onClick={() => {
                            remove(field.name)
                          }}
                          icon={<DeleteIcon />}
                        />
                      </div>
                    </Col>
                  ))}
                  <Col lg={24} md={24} sm={24} className="sales-btn">
                    <Button
                      onClick={() => {
                        add()
                      }}
                      type="link"
                      className="add-package"
                      label="Add another accontant"
                      icon={<AddPackage />}
                      disabled={isDisableAdd.accountantsList}
                    />
                  </Col>
                </Row>
              )
            }}
          </Form.List>
        </Col>
      </Row>
    </>
  )
}

export default OtherDetailsPanel
